// TermDetail.js
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import glossary from './components/GlossaryFolder/glossary.json';
import './components/GlossaryFolder/glossary/glossary.css';
import { Container, Row, Col } from 'react-bootstrap';
import content from './components/content.json';
import ErrorPage from './components/error/ErrorPage'

const Navbar = lazy(() => import("./components/NavBar/HeaderNav"));
const SubHeader = lazy(() => import("./components/SubHeader/SubHeader"));
const Program = lazy(() => import("./components/GlossaryFolder/programe/program"));
const LoanService = lazy(() => import("./components/LoanService/LoanService"));
const Summary = lazy(() => import("./components/GlossaryFolder/Summary/summary"));
const Footer = lazy(() => import("./components/FooterNew/index"));
const Subscribe = lazy(() => import("./components/GlossaryFolder/Subscribe/Subscribe"));

function TermDetail() {
  const [data, setData] = useState(null); // Set initial state as null
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = Date.now(); // Generate a unique timestamp
        const response = await fetch(`${process.env.REACT_APP_JSON_CDN_URL}?timestamp=${timestamp}`);

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error('Error fetching links:', response.status);
          setData(content); // Use local JSON data as a fallback if fetching fails
        }
      } catch (error) {
        console.error('Error fetching links:', error);
        setData(content); // Use local JSON data as a fallback if fetching fails
      }
    };

    if (!data) {
      fetchData(); // Fetch JSON only if the links data is not available
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      setData(content); // Set local JSON data as a fallback initially
    }
  }, []);

  const { termurl, term } = useParams();
  const [termData, setTermData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const timestamp = Date.now(); // Generate a unique timestamp
    const fetchTermData = async () => {
      try {
        // Fetch glossary data from the specified URL
        const response = await fetch(`${process.env.REACT_APP_CAPITAL_MARKET_GLOSSARY_JSON_CDN_URL}?timestamp=${timestamp}`);
        console.log("Response:", response);
        const glossaryData = await response.json();
        const termInfo = glossaryData.find((item) => item.termurl === termurl);
      
        if(termInfo){
          setTermData(termInfo);
        }
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        }
        if (!termInfo) {
          throw new Error(`Term not found: ${term}`);
        }
      } catch (error) {
        console.error('Error fetching glossary term data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTermData();
  }, [term]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching data
  }

  if (error) {
    return <ErrorPage /> // Display an error message if there's an issue with the API request
  }

  // const { termurl } = useParams();
  // const termData = glossary.find((item) => item.termurl === termurl);

  // if (!termData) {
  //   return <div>Term not found</div>;
  // }

  return (
    <div>
      {data && termData ? (
        <>
          <Suspense fallback={<div />}>
            <Navbar data={data.header} />
          </Suspense>
          <div className="header-bg-glossary">
          <Suspense fallback={<div />}>
            <SubHeader data={data.header} />
          </Suspense>
          </div>
          <Container className="term-detail-container">
            <Row>
              <Col className='glossary-col'>
                <h2 style={{ color: '#FF541C' }}>{termData.term}</h2>
                <p className="definition-text">{termData.definition}</p>
              </Col>
            </Row>
          </Container>
          <Suspense fallback={<div />}>
            <Program data={data.program} />
          </Suspense>
          <Suspense fallback={<div />}>
            <Summary fetchpriority="low" data={data.summary} />
          </Suspense>
          <Suspense fallback={<div />}>
            <Subscribe fetchpriority="low" />
          </Suspense>
          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default TermDetail;
