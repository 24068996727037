import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import Home from "./Home";
import GlossaryHome from "./glossaryHome";
import TermDetail from "./TermDetail";
import DownloadRateSheet from "./DownloadRateSheetHome";
import TurnTimeCommitment from "./TurnTimeCommitmentHome";
import FHAProgramHome from "./FHAProgramHome";
import VAProgramHome from "./VAProgramHome";
import USDAProgramHome from "./USDAProgramHome";
import FHAMultifamilyLending from "./FHAMultifamilyLendingHome"


function App() {
  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to the console.
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/glossary" element={<GlossaryHome />} />
          <Route exact path="/:termurl" element={<TermDetail />} />
          <Route exact path="/download-rate-sheets" element={<DownloadRateSheet />} />
          {/* <Route exact path="/turn-time-commitment" element={<TurnTimeCommitment />} /> */}
          {/* <Route exact path="/fha-programs" element={<FHAProgramHome />} />
          <Route exact path="/va-programs" element={<VAProgramHome />} />
          <Route exact path="/usda-rural-housing" element={<USDAProgramHome />} /> */}
          {/* <Route exact path="/fha-multifamily-lending" element={<FHAMultifamilyLending />} /> */}
        </Routes>
      </Router>
    </CacheBuster>
  );
}

export default App;
