import { Container, Row, Col, Button } from "react-bootstrap";
import Footer from '../FooterNew/index';
import React, { Suspense } from "react";
import './error.css';

function ErrorPage() {
    return (
        <div>
            <Container className="justify-content-center error-text pt-5 mb-2">
                <Row>
                    <Col sm={12}>
                        <h1 style={{fontWeight: 'bold'}}>Oh no!</h1>
                    </Col>
                    <Col sm={12}>
                        <p>Sorry, you have reached the page which has either been moved or is under update.</p>
                        <p>If you are seeing this error, please write us at <a href="mailto:inquiry@swmc.com" className="text-decoration-none error-text-link">inquiry@swmc.com</a> and let us know the page you were trying to access when the error occurred. We apologize for the inconvenience and thank you for your interest in Sun West Mortgage Company, Inc.</p>
                    </Col>
                </Row>
                <Row>
                    <a href='/' ><Button className='home-btn'>GO TO HOMEPAGE</Button></a>
                </Row>
            </Container>
            <Suspense fallback={<div />}>
                        <Footer />
                    </Suspense>     
        </div>
    );
}

export default ErrorPage;