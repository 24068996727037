/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/

import React, { lazy, Suspense, useEffect, useState } from "react";
import "../src/App.css";
import content from "./components/content.json";


const Navbar = lazy(() => import("./components/NavBar/HeaderNav"));
const Header = lazy(() => import("./components/Header/Header.js"));
const Bullets = lazy(() => import("./components/Bullets/Bullets.js"));
const LoanService = lazy(() => import("./components/LoanService/LoanService"));
const Subscribe = lazy(() => import("./components/Subscribe/Subscribe"));
const ContactUs = lazy(() => import("./components/ContactUs/ContactUsModal"));
const Footer = lazy(() => import("./components/FooterNew/index"));
const Disclaimer = lazy(() => import("./components/disclaimer/disclaimer"));



function Home() {
  // const isProduction = process.env.NODE_ENV === 'production';

  

  const [data, setData] = useState(null); // Set initial state as null
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = Date.now(); // Generate a unique timestamp
        const response = await fetch(`${process.env.REACT_APP_JSON_CDN_URL}?timestamp=${timestamp}`);

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error('Error fetching links:', response.status);
          setData(content); // Use local JSON data as a fallback if fetching fails
        }
      } catch (error) {
        console.error('Error fetching links:', error);
        setData(content); // Use local JSON data as a fallback if fetching fails
      }
    };

    if (!data) {
      fetchData(); // Fetch JSON only if the links data is not available
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      setData(content); // Set local JSON data as a fallback initially
    }
  }, []);

  return (
    <>
     
        <div className="bodyContainer">
          {data ? (
            <>
              <Suspense fallback={<div />}>
                <Navbar data={data.header} />
              </Suspense>
              <Suspense fallback={<div />}>
                <Header data={data.header} />
              </Suspense>
              <Suspense fallback={<div />}>
                <Bullets data={data.bullets} />
              </Suspense>
              <Suspense fallback={<div />}>
                <LoanService data={data.loanService} />
              </Suspense>
              <Suspense fallback={<div />}>
                <Subscribe fetchpriority="low" />
              </Suspense>
              <Suspense fallback={<div />} >
                <ContactUs fetchpriority="low"/>
              </Suspense>
              <Suspense fallback={<div />}>
                <Disclaimer data={data.disclaimer} />
              </Suspense>
              <Suspense fallback={<div />}>
                <Footer />
              </Suspense>
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      
    </>
  );
}

export default Home;
